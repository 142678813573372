@use '@/styles/utils/mixins.scss' as *;

.heroSwiper {
  .heroSwiperWrap {
    .headingElement {
      position: relative;
      z-index: 2;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'KiaSignature';
        font-weight: 600;
        font-style: normal;
        &.titleXl {
          font-size: 60px;
          line-height: 60px;
          @include tab() {
            font-size: 36px;
            line-height: 43.2px;
          }
        }
        &.titleLg {
          font-size: 48px;
          line-height: 1;
          @include tab() {
            font-size: 36px;
            line-height: 1;
          }
        }
        &.titleMd {
          font-size: 20px;
          line-height: 1;
        }
        &.titleXs {
          font-size: 16px;
          line-height: 1;
        }
      }
      .bannerCta {
        padding: 14px 15px 12px;
        @media screen and (max-width: 576px) {
          display: block;
        }
      }
    }
    position: relative;
    z-index: 2;
    background-color: $kiaMidnightBlack;

    .heroImageWrapper {
      @media screen and (max-width: 767px) {
        position: relative;
        min-height: 450px;
      }
      @media screen and (max-width: 576px) {
        min-height: 303px;
      }

      .heroImage {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
    }
  }
  .swiper-button-prev {
    left: 24px;
  }
  .swiper-button-next {
    right: 24px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    font-size: 0;
    width: 48px;
    height: 48px;
    margin-top: -26px;
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 48px;
    height: 48px;
  }
  .swiper-button-prev::after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='49' viewBox='0 0 48 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24' cy='24.4531' r='24' fill='%2305141F' fill-opacity='0.4'/%3E%3Cpath d='M29.3334 23.7865H21.2201L24.9467 20.0598L24.0001 19.1198L18.6667 24.4531L24.0001 29.7865L24.9401 28.8465L21.2201 25.1198H29.3334V23.7865Z' fill='white'/%3E%3C/svg%3E%0A");
  }
  .swiper-button-next::after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='49' viewBox='0 0 48 49' fill='none'%3E%3Ccircle cx='24' cy='24.4531' r='24' fill='%2305141F' fill-opacity='0.4'/%3E%3Cpath d='M24 19.1198L23.06 20.0598L26.78 23.7865H18.6666V25.1198H26.78L23.06 28.8465L24 29.7865L29.3333 24.4531L24 19.1198Z' fill='white'/%3E%3C/svg%3E");
  }
}
